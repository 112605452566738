import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { CardMedia, CardHeader } from '@mui/material';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  media: {
    width: '80%',
    marginTop: props => props.marginTop,
    marginLeft: props => props.marginLeft,
    '@media (max-width:600px)': {
      marginLeft: props => props.marginLeftMobile,
      marginRight: props => props.marginRightMobile,
      marginTop: '10%'
    },
  },
  text: {
    marginLeft: props => props.marginLeftText,
    marginTop: props => props.marginTopText,
    lineHeight: '1.3',
    color: '#051E42',
    fontWeight: 600,
    '@media (max-width:600px)': {
      marginLeft: props => props.marginLeftTextMobile,
    },
  }
});

function RoundedPlainCard({ image, title, props }) {
  const classes = useStyles(props);

  return (
    <Card sx={{
      maxWidth: 360,
      background: '0% 0% no-repeat padding-box',
      boxShadow: 'inset 2px 2px 25px #FFFFFF, 0px 3px 6px #00000029',
      borderRadius: '20px',
      height: '100%',
      display: 'block',
      justifyContent: 'center',
      width:'100%'
    }}>
      <CardHeader
        avatar={
          <CardMedia className={classes.media} component="img" image={image} />
        }
        title={
          <Box className={classes.text}>
            <Typography className={classes.text} variant="body2">
              {title}
            </Typography>
          </Box>}
      />
    </Card>
  );
}

export default RoundedPlainCard;