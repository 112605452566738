import React from 'react';
import Question from './Question';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';
import questions from '../assets/data/questions-contratar.json';


function QuestionsContratar() {
  return(
    <>
      <Divider orientation="horizontal" flexItem />
      <Stack sx={{ marginTop: '16px' }} divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
        {questions.questions.map((question, i) =>
          <Question key={i} question={question.question} answer={question.answer} />
        )}
      </Stack>
    </>
  )
}

export default QuestionsContratar;