import * as React from 'react';
import Grid from '@mui/material/Grid';
import RoundedPlainCard from './RoundedPlainCard';

const RoundedPlainCardsLuzYGas = () => {
  return (
    <Grid display="flex"
    justifyContent="center"
    alignItems="stretch"
    sx={{margin:{md:'40px 0px'}}}>
      <Grid container md={10} spacing={{ xs: 1, md:2, xl: 2 }} columns={{ xs: 4, sm: 8, md:10, xl: 12 }}   sx={{justifyContent:{ xs:'center', md:'center', xl: 'space-between'}}}  justifyContent="space-between">
        <Grid item xs={3.5} xl={2.75} display="flex" justifyContent="center">
          <RoundedPlainCard image={require('../../assets/images/handshake.png')}
            title="Asesoramiento personalizado de expertos en energía."
            props={{ marginTop: '10%', marginLeft: '10%', marginLeftMobile: '10%', marginTopText: '0%', marginLeftTextMobile: '3%' }} />
        </Grid>
        <Grid item xs={3.5} xl={2.75} display="flex" justifyContent="center">
          <RoundedPlainCard image={require('../../assets/images/lightbulb.png')}
            title="Energía 100% renovable"
            props={{ marginTop: '10%', marginLeft: '10%', marginLeftMobile: '15%', marginLeftTextMobile: '2%' }} />
        </Grid>
        <Grid item xs={3.5} xl={2.75} display="flex" justifyContent="center">
          <RoundedPlainCard image={require('../../assets/images/mobile.png')}
            title="Contrata la luz y el gas sin complicaciones ni papeleos."
            props={{ marginTop: '10%', marginLeft: '10%', marginLeftMobile: '15%', marginRightMobile: '30%', marginTopText: '5%', marginLeftTextMobile: '4%' }} />
        </Grid>
        <Grid item xs={3.5} xl={2.75} display="flex" justifyContent="center">
          <RoundedPlainCard image={require('../../assets/images/pig.png')}
            title="Si encuentras una tarifa más barata, ¡la igualamos!"
            props={{ marginTop: '0%', marginLeft: '10%', marginLeftMobile: '15%', marginTopText: '5%', marginLeftTextMobile: '3%' }} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RoundedPlainCardsLuzYGas;