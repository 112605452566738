import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { TextField, Typography, FormControlLabel, Button, Stack } from '@mui/material';
import ColoredCheckbox from '../checkbox/ColoredCheckbox';
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import CallingDialog from '../dialog/CallingDialog';
import { launchC2C } from '../utils/PostLead';
import { isPhoneValid } from '../utils/Functions';
import PrivacyDialog from '../dialog/PrivacyDialog';

const useStyles = makeStyles({
  input: {
    outline: 'none !important',
    border: '1px solid red',
    boxShadow: '0 0 10px #719ECE',
  },
  stack: {
    margin: '30% 10% auto 10%',
    '@media (max-width:1400px)': {
      margin: '10% 10% auto 10%'
    },
  },
  stackHeader: {
    '@media (max-width:1200px)': {
      marginTop: '7%'
    },
  },
  box: {
    height: '101%',
    width: '30%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: 'inset 2px 2px 25px #FFFFFF, 0px 3px 6px #00000029',
    borderRadius: '20px',
    position: 'absolute',
    float: 'right',
    top: '-1%',
    right: '20px',
    '@media (max-width:1200px)': {
      position: 'relative',
      width: '80%',
      margin: '0 auto',
      marginTop: '-10%',
      float: 'none',
      right: '0px',
    },
    '@media (max-width:500px)': {
      marginTop: '-20%',
    },
  },
  button: {
    '&:hover': {
      backgroundColor: '#F39D0A',
    },
    '@media (max-width:1200px)': {
      marginBottom: '7%'
    },
  },
  a: {
    textDecoration: 'none !important',
  }
});

function Banner() {
  const classes = useStyles();
  const { search } = useLocation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isOpenCallingDialog, setIsOpenCallingDialog] = useState(false);
	const [isOpenPrivacyDialog, setIsOpenPrivacyDialog] = useState(false);

  const handleCloseCallingDialog = () => {
    setButtonClicked(false);
  };

  const handleClosePrivacyDialog = () => {
    setIsOpenPrivacyDialog(false);
  };

  const handleTextFieldChange = (event) => {
    setPhoneNumber(event.target.value);
  }

  const handleCheckboxChange = (event) => {
    setChecked(!checked);
  }

  
  const handleClickOpen = () => {
    setIsOpenCallingDialog(true);
    setTimeout(() => {
      setIsOpenCallingDialog(false);
      handleCloseCallingDialog();
    }, 3000);
  };
  const handleClicPrivacy = () => {
    setIsOpenPrivacyDialog(true);
  };

  return (
    <div>
      <CallingDialog
        open={isOpenCallingDialog}
        onClose={handleCloseCallingDialog}
      />
      <PrivacyDialog
				open={isOpenPrivacyDialog}
				onClose={handleClosePrivacyDialog}
			/>
      <Box sx={{ position: 'relative' }}>
        <picture>
          <source media="(max-width: 500px)" srcSet={require('../assets/images/banner-mobile.png')} />
          <img width="100%" height="auto" src={require('../assets/images/banner.png')} alt="banner" />
        </picture>
        <Box className={classes.box}>
          <Stack className={classes.stack}>
            <Typography className={classes.stackHeader} variant="h4">¿Quieres pagar menos por tu factura de luz y gas?</Typography>
            <TextField onChange={handleTextFieldChange} sx={{ width: '100%', marginTop: '10px' }} label={<Typography sx={{ marginTop: '6px' }} variant="body2">Escribe tu teléfono</Typography>} variant="outlined" />
            <FormControlLabel control={<ColoredCheckbox onChange={handleCheckboxChange} />} label={<Typography variant="subtitle2">He leído y acepto la <Link 
            onClick={() => {
              handleClicPrivacy();
            }}
            style={{ textDecoration: 'none', color: '#FF6200' }}>política de privacidad</Link> y consiento el tratamiento de mis datos personales para todas las finalidades contenidas en la misma</Typography>} />
            {!checked && buttonClicked && <Typography variant="subtitle2" color="red">Debes aceptar la política de privacidad</Typography>}
            {checked && buttonClicked && !isPhoneValid(phoneNumber) && <Typography variant="subtitle2" color="red">El número de teléfono no es válido</Typography>}
            <Button className={classes.button}
              onClick={() => {
                setButtonClicked(true);
                if (checked && isPhoneValid(phoneNumber)) {
                  launchC2C(phoneNumber);
                  handleClickOpen();
                }
              }}
              sx={{
                background: '#FF6200 0% 0% no-repeat padding-box',
                boxShadow: 'inset 2px 2px 10px #FFFFFFBC, 0px 3px 6px #00000029',
                borderRadius: '10px',
                height: '80px',
                width: '100%'
              }}>
              <Typography color="white" variant="subtitle1">¡Quiero pagar menos!</Typography>
            </Button>
          </Stack>
        </Box>
      </Box>
    </div>
  )
}

export default Banner;