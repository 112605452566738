import { Typography, Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


function PrivacyDialog(props) {

  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };


  return (
      <Dialog onClose={handleClose} open={open}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{marginTop: '4%', textAlign: 'left'}}>
          <Typography sx={{ marginTop: '10px' }} variant="h4">Política de privacidad</Typography>
          <Typography sx={{ marginTop: '20px' }} variant="h5">1. Responsable del tratamiento de los datos</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">El responsable del tratamiento es la persona jurídica que determina los fines y medios del tratamiento de Datos Personales. En otras palabras, el responsable decide cómo y con qué finalidades se tratan los Datos Personales. </Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">A los efectos de la presente Política de Privacidad, el responsable del tratamiento de los Datos Personales es: </Typography>
          <ul>
            <li>
              <Typography variant="body2">BYSIDECAR, S.L.</Typography>
            </li>
            <li>
              <Typography variant="body2">CIF: ESB44600989</Typography>
            </li>
            <li>
              <Typography variant="body2">Dirección postal: Edificio Proa (Matogrande) C/ García Sabell nº 1, plantas 7 y 8. (A Coruña)</Typography>
            </li>
            <li>
              <Typography variant="body2">Correo electrónico: hello@bysidecar.com</Typography>
            </li>
          </ul>

          <Typography sx={{ marginTop: '20px'}} variant="h5">2. Objeto de la Política de Privacidad</Typography>
          <Typography sx={{ marginTop: '10px'}} variant="body2">El objeto de la presente política de privacidad es informarle en lo relativo a las normas que regirán el tratamiento de los datos de carácter personal que hayan facilitado a BYSIDECAR, S.L., S.L. mediante la web www.mejortarifaluzygas.es (en adelante “La Web”). 
          <br/>Mediante esta Política de Privacidad nuestra intención es informar a los usuarios sobre la recopilación y tratamiento de datos personales que lleva a cabo el responsable de la Web, los fines para los que utilizamos los datos y las formas en que se gestionan tales datos, en estricto cumplimiento con el principio de transparencia en virtud de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (“LOPDGDD”), así como lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y libre circulación de estos datos ("RGPD"). 
          <br/>A estos efectos, el responsable del tratamiento de los datos personales es BYSIDECAR, S.L.  y los datos de contacto se encuentran recogidos en la Sección de Identificación del responsable de esta Política de Privacidad.</Typography>
          <Typography sx={{ marginTop: '20px'}} variant="h5">3. Finalidad del tratamiento de los datos personales</Typography>
          <Typography sx={{ marginTop: '10px'}} variant="body2">Los datos que Vd. facilita a BYSIDECAR, S.L., tienen como finalidad: </Typography>
          <ul>
            <li>
              <Typography variant="body2">Gestionar las comunicaciones recibidas a través del correo electrónico o del formulario de contacto;</Typography>
            </li>
            <li>
              <Typography variant="body2">Gestionar el proceso de selección de personal para las empresas del Grupo y de la relación con los candidatos/as a empleo;</Typography>
            </li>
            <li>
              <Typography variant="body2">Enviarle información por cualquier medio, incluido el correo electrónico y/o medio equivalente, sobre servicios adecuados y personalizados que Ud. solicite en cada momento; </Typography>
            </li>
            <li>
              <Typography variant="body2">Mantenerle informado, por cualquier medio de comunicación electrónica, de aquellas ofertas, novedades y promociones comerciales de terceras entidades, incluyendo el análisis y la formación de perfiles y, en general, la realización de acciones comerciales, de promoción y/o marketing relacionadas con proveedores de transportes, telecomunicaciones, alimentación, actividad energética, deportes, seguros, servicios inmobiliarios, financieros y bancarios así como de gran consumo, alimentación y retail, con el fin de ofrecerle sus productos o servicios y realizar ofertas comerciales, siempre y cuando Ud. haya marcado el check legal autorizando el envío de dichas comunicaciones comerciales.</Typography>
            </li>
            <li>
              <Typography variant="body2">Aquellas actividades propias del objeto social del responsable del tratamiento. </Typography>
            </li>
          </ul>
          <Typography sx={{ marginTop: '10px'}} variant="body2">En todo caso, la base legal para el tratamiento de sus datos personales será el interés legítimo del responsable, así como, en su caso, el consentimiento prestado por el usuario para el tratamiento de sus datos personales para las finalidades indicadas anteriormente. </Typography>
          
          <Typography sx={{ marginTop: '20px'}} variant="h5">4. Consentimiento del usuario</Typography>
          <Typography sx={{ marginTop: '10px'}} variant="body2">El envío de los datos mediante su registro en nuestros formularios, mediante el uso de mensajes de correo electrónico u otro medio, nos proporciona voluntariamente su información. Dicha información puede incluir su nombre, comentarios, opiniones, la dirección de correo electrónico o el teléfono.
          <br/>El Usuario garantiza que los datos personales facilitados a BYSIDECAR, S.L.  son veraces y se hace responsable de comunicar a ésta cualquier modificación de los mismos, siendo responsable de la información falsa o inexacta que realice y de los perjuicios que cause a BYSIDECAR, S.L..
          <br/>Respecto a aquella información que sea remitida por menores de edad, será requisito imprescindible que el remitente haya obtenido previamente el consentimiento paterno, del tutor o del legal representante para que los datos de carácter personal puedan ser objeto de tratamiento automatizado.</Typography>
          
          <Typography sx={{ marginTop: '20px'}} variant="h5">5. Transmisión de datos</Typography>
          <Typography sx={{ marginTop: '10px'}} variant="body2">Para cumplir con las finalidades señaladas en la presente Política de Privacidad, es necesario que compartamos sus datos personales a entidades pertenecientes al grupo empresarial del que forma parte BYSIDECAR, S.L., así como a terceras entidades (destinatarios) en el supuesto de que:</Typography>
          <ul>
            <li>
              <Typography variant="body2">Usted haya proporcionado su consentimiento explícito para uno o más fines específicos según el art. 6.1.a) del RGPD.</Typography>
            </li>
            <li>
              <Typography variant="body2">La divulgación según el art. 6.1.f) del RGPD sea necesaria para la aseveración, ejercicio o defensa de reclamaciones judiciales.</Typography>
            </li>
            <li>
              <Typography variant="body2">Para el caso en el que exista una obligación legal para la divulgación según el art. 6.1.c) del RGPD. </Typography>
            </li>
            <li>
              <Typography variant="body2">Sea legalmente permisible y necesario para cumplir con las obligaciones contractuales según el art. 6.1.b) del RGPD.</Typography>
            </li>
          </ul>
          <Typography sx={{ marginTop: '10px'}} variant="body2">En aquellos supuestos en los que BYSIDECAR, S.L. contrate los servicios de terceros, sus datos personales podrán ser tratados por un encargado del tratamiento.
          <br/>No se producirá la transmisión de sus datos personales con fines distintos a los enumerados anteriormente
          <br/>Sin perjuicio de lo anterior, le informamos que las referidas entidades se encuentran ubicadas en el Espacio Económico Europeo, por lo que la transferencia de datos personales estará bajo un nivel de protección de datos seguro y garantizando la confidencialidad de los mismos.</Typography>
          
          <Typography sx={{ marginTop: '20px'}} variant="h5">6. Derechos del usuario</Typography>
          <Typography sx={{ marginTop: '10px'}} variant="body2">El Usuario podrá revocar el consentimiento prestado y ejercer su derecho de acceso, rectificación, cancelación y oposición, portabilidad y supresión al tratamiento de sus datos personales, en los términos y condiciones previstos en la normativa vigente en materia de protección de datos de carácter personal.
          <br/>Para su mayor comodidad, podrá ejercer los derechos antes mencionados dirigiéndose a BYSIDECAR, S.L., A/A: Departamento de protección de datos, Edificio Proa (Matogrande) C/ García Sabell nº 1, plantas 7 y 8. (A Coruña) o bien a nuestra dirección de correo electrónico: 
          <br/>Asimismo, se informa a los Usuarios que tienen derecho a presentar una reclamación ante la Agencia Española de Protección de Datos <a href="https://www.aepd.es/es" target='_blank' rel="noreferrer">(www.aepd.es)</a> en caso de que consideren vulnerados sus derechos.</Typography>

          <Typography sx={{ marginTop: '20px'}} variant="h5">7. Conservación de sus datos</Typography>
          <Typography sx={{ marginTop: '10px'}} variant="body2">BYSIDECAR, S.L. únicamente conservará y tratará los datos de los Usuarios mientras permanezcan las finalidades para las que han sido recabados y, en cualquier caso, hasta el vencimiento de los plazos legalmente establecidos.
          <br/>El tratamiento de los datos para la remisión de comunicaciones informativas se realizará mientras el Usuario no revoque su consentimiento y mientras permanezcan inalterados los fines para los cuales se han recabado dichos datos de carácter personal.
          <br/>Posteriormente, se procederá al bloqueo de los datos de carácter personal, que permanecerán a disposición exclusiva de los jueces y tribunales, el Ministerio Fiscal o las autoridades competentes para la exigencia de posibles responsabilidades nacidas del tratamiento, durante el plazo de prescripción de las mismas. Cumplido el citado plazo, BYSIDECAR, S.L.  procederá a la supresión de los datos. </Typography>

          <Typography sx={{ marginTop: '20px'}} variant="h5">8. También recibimos información técnica cuando usa nuestros servicios</Typography>
          <Typography sx={{ marginTop: '10px'}} variant="body2">Actualmente cuando usa un sitio web, una aplicación móvil u otro servicio de Internet, casi siempre se crea y se registra determinado tipo de información de manera automática.
          <br/>A continuación, le informamos de algunos tipos de información que recopilamos cuando Ud. Utiliza este sitio web:</Typography>
          <ul>
            <li>
              <Typography variant="body2">Datos de registro: Cuando navegas por nuestra página web nuestros servidores registran automáticamente información (“datos de registro”), que incluye los datos que envía su navegador cada vez que visitas un sitio web.  Estos datos de registro pueden incluir tu dirección de protocolo de Internet, las direcciones de las páginas web que hayas visitado y que tenían contenidos de BYSIDECAR, S.L., el tipo de navegador y su configuración, la fecha y hora de la petición, como utilizaste la web y los datos contenidos en cookies.</Typography>
            </li>
            <li>
              <Typography variant="body2">Datos de cookies: Según el modo en que accedas a nuestros productos, podríamos usar cookies (“un pequeño archivo de texto que envía tu ordenador cada vez que visitas nuestro sitio web”), o bien alguna tecnología similar para almacenar datos de registro. Cuando usamos cookies, pueden ser de “sesión” (permanentes activas hasta que se cierra el navegador) o “persistentes” (están activas hasta que Ud. o el navegador las borra). Un ejemplo, podríamos usar cookies para almacenar tus preferencias de idioma u otros ajustes, de forma que no sea necesario configurarlos cada vez que visites la Web.</Typography>
            </li>
            <li>
              <Typography variant="body2">Información del dispositivo: Además de registrar datos, también podríamos recopilar información acerca del dispositivo con el que accedes a la Web, como el tipo, su configuración, su identificación única, el sistema operativo que utilizas o datos sobre bloqueos. La recopilación de este tipo de información suele depender del tipo de dispositivo que utilices y de su configuración. La información es diferente si utilizas un Mac, un PC, un Iphone o un teléfono Android. Para saber el tipo de información que nos envía tu dispositivo, puedes consultar las políticas del fabricante del dispositivo o del proveedor de software. </Typography>
            </li>
          </ul>
          <Typography sx={{ marginTop: '20px'}} variant="h5">9. Seguridad</Typography>
          <Typography sx={{ marginTop: '10px'}} variant="body2">BYSIDECAR, S.L. adopta los niveles de seguridad requeridos por la normativa vigente en materia de protección de datos en cada momento, es decir, las medidas necesarias para evitar la alteración, pérdida, tratamiento o acceso no autorizado a los datos personales, habida cuenta en todo momento del estado de la tecnología. No obstante lo anterior, las medidas de seguridad en Internet no son inexpugnables y pueden existir filtraciones por actuaciones dolosas de terceros.
          <br/>Respecto a cualquier tipo de duda o controversia relativa a nuestra política de privacidad de datos puede ponerse en contacto con nosotros dirigiéndose a cualquiera de las direcciones postales y/o electrónicas indicadas en el apartado de identificación de los responsables del tratamiento.</Typography>

          <Typography sx={{ marginTop: '20px'}} variant="h5">10. Modificaciones de la Política de Privacidad</Typography>
          <Typography sx={{ marginTop: '10px'}} variant="body2">BYSIDECAR, S.L. podrá cambiar periódicamente esta política y, en tal caso, publicaremos dichos cambios en esta página web. El hecho de continuar navegando por la Web después de la entrada en vigor de los referidos cambios se entenderá como su aceptación de la política así modificada. En caso de que los cambios sean de una mayor importancia, lo notificaremos de manera más destacada o solicitaremos su consentimiento, tal y como especifica la ley.</Typography>

          <Typography sx={{ marginTop: '20px'}} variant="h5">11. Ley Aplicable y Fuero</Typography>
          <Typography sx={{ marginTop: '10px', marginBottom:'20px'}} variant="body2">Este Sitio Web y las condiciones contenidas en esta Política de Privacidad se rigen por la legislación española. Para cualquier cuestión litigiosa o conflicto derivado o relacionado con el acceso y la utilización del Sitio Web, o de la interpretación y cumplimiento de las mencionadas condiciones las partes se someten a la jurisdicción y competencia de los Juzgados y Tribunales de la ciudad de A Coruña.</Typography>
      </DialogContent>
    </Dialog>

  );
}

export default PrivacyDialog;
