import { pushToDataLayerDDI, launchC2CFormKwh } from "./PostLead";

export const isPhoneValid = (value) => {
  var str = value.toString().replace(/\s/g, "");
  return str.length === 9 && /^[6789]{1}[0-9]{8}$/.test(str);
};

export const isResponsive = (width) => {
  return width <= 640;
};

export const isResponsiveTablet = (width) => {
  return width <= 1116;
};

export const handleConvertMessage = (clientKwh , setError, setConvertedValue , setMensajeAhorro) => {
  const sanitizedValue = clientKwh.replace(/[^0-9.,]/g, '');
  const dotValue = sanitizedValue.replace(/,/g, '.');
  const formattedValue = parseFloat(dotValue).toFixed(2);
  const formattedValueLead = formattedValue.replace(".", ",");;

  if (isNaN(formattedValue)) {
    setError('Debes introducir números. Ejemplo: 0.23');
  } else {
    setError('');
    setConvertedValue(formattedValueLead);
    calculadoraKwh(formattedValue, setMensajeAhorro);
  }
};

export const handleConvert2lead = (phoneNumber, mail, clientKwh) => {
  const sanitizedValue = clientKwh.replace(/[^0-9.,]/g, '');
  const dotValue = sanitizedValue.replace(/,/g, '.');
  const formattedValue = parseFloat(dotValue).toFixed(2);
  const formattedValueLead = dotValue.replace(".", ",");;
  launchC2CFormKwh(phoneNumber, mail, formattedValueLead);
};

export const calculadoraKwh =(formattedValue, setMensajeAhorro) =>{
  let precioRepsol = 0.13;
  let ahorro = ((precioRepsol/formattedValue) - 1)*100;  
 
  if (ahorro < 0){
    ahorro = Math.abs( ahorro );
    ahorro= parseFloat(ahorro).toFixed(0);
    setMensajeAhorro(`<h3>Contrata ya nuestra tarifa y ahorra desde hoy un</h3> <h2>${ahorro}%</h2> <h4>En breve te llamaremos para mejorar tu actual oferta.</h4>`);
  }else{
    setMensajeAhorro("<h3>Evita sorpresas y sustos en tu tarifa.</h3> <h4>En breve te llamaremos para que contrates el mejor precio durante 1 año y no pagues de más.</h4>");
  }

 return setMensajeAhorro;
};

export const dial = (phone) => {
  window.open(`tel:${phone}`, "_self", "noopener");
  pushToDataLayerDDI(phone);
};
