import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import C2CDialog from '../../dialog/C2CDialog';
import { Grid } from '@mui/material';

const useStyles = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: '#041E42',
    },
    '@media (max-width:1200px)': {
      marginBottom: '7%'
    },
  },
  text: {
      fontSize: '18px',
    '@media (min-width:900px) and (max-width:1500px)': {
      fontSize: '14px'
    },
  },
  heading1: {
    fontSize: '27px',
  '@media (min-width:900px) and (max-width:1500px)': {
    fontSize: '22px'
  },  
  '@media (max-width:900px)': {
    display:'none'
  }
},
  heading2: {
    fontSize: '40px',
  '@media (min-width:900px) and (max-width:1500px)': {
    fontSize: '20px'
  },
  '@media (max-width:600px)': {
    fontSize: '1.5rem'
  }
}
});

function ComplexCardGas({ suptitle, title, ctaText, descriptionBold1, description1, descriptionBoldInner1, descriptionInner1, descriptionBoldEnd1, descriptionBold2, description2, descriptionBold3, description3 }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <C2CDialog
        open={open}
        onClose={handleClose}
      />
      <Card sx={{
        maxWidth: 360,
        background: '0% 0% no-repeat padding-box',
        boxShadow: {xs:'inset 2px 2px 25px #FFFFFF, 0px 3px 6px #00000029',  md:'none'},
        borderRadius: '20px',
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        flexDirection: 'column'
      }}>
        <CardContent sx={{
          padding: '20px',
          display: 'flex',
          flex: '1 0 auto',
          alignItems: 'flex-start',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          <Typography  className={classes.heading1} sx={{ lineHeight: '1.2' }} style={{  color: '#041E42' }} variant="h2">
            {suptitle}
          </Typography>
          <Typography  className={classes.heading2} sx={{ lineHeight: '1.2' }} style={{  color: '#041E42' }} variant="h2">
            {title}
          </Typography>
          <Typography className={classes.text} sx={{ marginTop: '13px', lineHeight: '1.2' }} style={{ color: '#041E42' }} variant="h4">
            {descriptionBold1}
          </Typography>
          <Grid sx={{ lineHeight: '0.8' }} style={{ color: '#041E42' }} variant="body2">
            <Typography className={classes.text} display="block" sx={{ lineHeight: '1.2' }} style={{ color: '#041E42' }} variant="body2">
            {description1}
            </Typography>
            <Typography  className={classes.text} display="inline" sx={{ lineHeight: '1.2', paddingBottom: '5px' }} style={{  color: '#041E42' }} variant="h4">
              {descriptionBoldInner1}
            </Typography>
            <Typography className={classes.text} display="inline" sx={{ lineHeight: '1.2' }} style={{ color: '#041E42' }} variant="body2">
              {descriptionInner1}
            </Typography>
            <Typography className={classes.text} display="inline" sx={{ lineHeight: '1.2', paddingBottom: '5px' }} style={{  color: '#041E42' }} variant="h4">
              {descriptionBoldEnd1}
            </Typography>
          </Grid>
          <Typography className={classes.text} sx={{ marginTop: '25px', lineHeight: '1.2' }} style={{ color: '#041E42' }} variant="h4">
            {descriptionBold2}
          </Typography>
          <Grid  sx={{ lineHeight: '1.3', paddingBottom: '30px' }} style={{ color: '' }} variant="body2">
            <Typography className={classes.text} sx={{ lineHeight: '1.2'}} style={{ color: '#041E42' }} variant="body2">
              {description2}
            </Typography>
            <Typography className={classes.text} display="inline" sx={{ lineHeight: '1.2', paddingBottom: '30px'}} style={{ color: '#041E42' }} variant="h4">
              {descriptionBold3}
            </Typography>
            <Typography className={classes.text} sx={{ lineHeight: '1.2'}} style={{ color: '#041E42' }} variant="body2">
              {description3}
            </Typography>
          </Grid>

          <Button className={classes.button}
            onClick={() => {
              handleClickOpen();
            }}
            sx={{
              background: '#FF8200',
              borderRadius: '10px',
              marginTop: 'auto',
              height: { xs:'50px', md:'60px'},
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Typography sx={{ lineHeight: '1.4rem' }} color="white" variant="subtitle1">{ctaText}</Typography>
          </Button>
        </CardContent>
      </Card>
    </>
  );
}

export default ComplexCardGas;