import Grid from "@mui/material/Grid";
import React from "react";
import CoinBagCard from "./CoinBagCard";
import HappyCard from "./HappyCard";
import SavingsCard from "./SavingsCard";
import ThumbsUpCard from "./ThumbsUpCard";

function Cards() {
  return (
    <Grid
      sx={{ margin: "-10px" }}
      container
      spacing={{ xs: 3, lg: 4 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item xs={3}>
        <SavingsCard
          image={require("../assets/images/savings-line.png")}
          title="Ahorra también fuera del hogar 400€ de dto en carburante"
          description1="Te regalamos hasta "
          descriptionBold="400€ de descuento "
          description2="en carburante en las Estaciones de Servicio solo por contratar con nosotros tu servicio de luz y gas. El ahorro y mejor precio, va mucho más allá de tu hogar."
        />
      </Grid>
      <Grid item xs={3}>
        <HappyCard
          image={require("../assets/images/happy.png")}
          title="Precio fijo sin sorpresas"
          description1="La tranquilidad que te da saber que todos los meses pagarás lo mismo. Consumas lo que consumas. Deja que los precios del mercado cambien lo que quieran. Tú pagarás siempre lo mismo, el mejor precio garantizado."
        />
      </Grid>
      <Grid item xs={3}>
        <ThumbsUpCard
          image={require("../assets/images/thumbs-up.png")}
          title="Encontramos tu tarifa de luz y gas ideal"
          description1="¿Sabes cuál es la mejor tarifa que se adapta a tus necesidades?. Déjate asesorar y empieza a notar el ahorro desde la primera factura."
        />
      </Grid>
      <Grid item xs={3}>
        <CoinBagCard
          image={require("../assets/images/coin-bag-line.png")}
          title="Si consumes, también ahorras"
          description1="Consigue descuentos en tu energía por tus compras en marcas como Amazon, El Corte Inglés, Booking… ¡Entre muchas otras! Disfruta de todas las ventajas y aprovecha los descuentos."
        />
      </Grid>
    </Grid>
  );
}

export default Cards;
