import React from 'react';
import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import C2CDialog from '../dialog/C2CDialog';

const useStyles = makeStyles({
  button: {
    position: 'fixed',
    width: '120px',
    height: '100px',
    marginRight: '0px',
    top: '50%',
    right: '0',
    textAlign: 'center',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    borderBottomLeftRadius: '5px',
    zIndex: '1110',
    backgroundColor: '#FF6200',
    '&:hover': {
      backgroundColor: '#F39D0A',
    },
    '@media (max-width:1200px)': {
      display: 'none',
    },
  }
});

const StickyC2C = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };


  return (
    <>
      <C2CDialog
        open={open}
        onClose={handleClose}
      />
      <Fab className={classes.button} size="medium" aria-label="add" onClick={() => {
        handleClickOpen();
      }}>
        <Stack sx={{ alignItems: 'center' }}>
          <PhoneIcon sx={{ color: 'white' }} />
          <Typography variant="body5" color="white">¿Hablamos?</Typography>
        </Stack>
      </Fab>
    </>
  );
}

export default StickyC2C;