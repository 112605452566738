import * as React from 'react';
import Grid from '@mui/material/Grid';
import ComplexCard from './ComplexCard';

const ComplexCardsLuz = () => {
    return (
        <Grid sx={{ marginTop: '20px' }} container justifyContent="center" alignItems="stretch">
            <Grid container md={10} spacing={{ xs: 4, xl: 1 }} columns={{ xs: 4, sm: 8, lg:11, xl: 12 }}  sx={{justifyContent:{ xs:'center', xl:'space-between'}}}  justifyContent="space-between">
                <Grid item xs={3.5} md={6} lg={4}  xl={2.75} display="flex" justifyContent="center">
                    <ComplexCard title="Tarifa ahorro Plus"
                        ctaText="¡Llámame!"
                        descriptionBold1="Precio fijo por kWh,"
                        description1="paga siempre lo mismo sin preocuparte por los horarios."
                        descriptionBold2="Que no te importe si sube la luz,"
                        description2="tú pagarás por lo que consumes y siempre al mismo precio" />
                </Grid>
                <Grid item xs={3.5} md={6} lg={4} xl={2.75} display="flex" justifyContent="center">
                    <ComplexCard title="Tarifa Plana"
                        ctaText="¡Llámame!"
                        descriptionBold1="Tu factura será igual todos los meses."
                        description2="Consume lo que necesites y paga siempre lo mismo,"
                        descriptionBold3=" sin regularizaciones"
                        description3=" a final de año" />
                </Grid>
                <Grid item xs={3.5} md={6} lg={4} xl={2.75} display="flex" justifyContent="center">
                    <ComplexCard title="Tarifa Mis 10 horas con descuento"
                        ctaText="¡Llámame!"
                        descriptionBold1="50% DE DESCUENTO"
                        description1="en las 10 horas al día que más consumes."
                        descriptionBold2="¡Disfruta de 300 horas al mes a mitad de precio!" />
                </Grid>
                <Grid item xs={3.5} md={6} lg={4} xl={2.75} display="flex" justifyContent="center">
                    <ComplexCard title="Tarifa Domingos Gratis"
                        ctaText="¡Llámame!"
                        descriptionBold1="Todos los domingos GRA-TIS."
                        description2="Aprovecha el día para poner la lavadora o el lavavajillas y no pagues nada" />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ComplexCardsLuz;