import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Logo from '../assets/images/logo.svg';
import LogoMobile from '../assets/images/logoMobile.svg';
import { makeStyles } from '@mui/styles';
import { Typography, Stack, Container, Grid, Button } from '@mui/material';
import useWindowSize from '../utils/WindowSize';
import Menu from './Menu';
import HamburgerMenu from './HamburgerMenu';
import C2CDialog from '../dialog/C2CDialog';
import { isResponsive, isResponsiveTablet, dial } from '../utils/Functions';

const useStyles = makeStyles({
  button: {
    background: 'linear-gradient(to right, #041E42 0%, #041E42 50%, #FF8200 50%, #FF8200 100%)',
    backgroundSize: '200% 100%',
    backgroundPosition: 'right bottom',
    transition: 'background-position 0.3s ease-in-out',

    '&:hover': {
      backgroundPosition: 'left bottom',

    },
    '@media (max-width:1200px)': {
      marginBottom: '13px',
    },
    "@media (max-width:600px)": {
      paddingLeft: "15px",
      paddingRight: "15px",
    },
  },
  buttonHidden: {
    visibility: 'hidden'
  },
  logo: {
    marginTop: '15px',
    marginBottom: '15px',
    marginLeft: '1%',
    height: '47px',
    '@media (max-width:500px)': {
      marginTop: '17px',
    },
  },
  text1: {
    '@media (max-width:500px)': {
      fontSize: '11px',
      lineHeight: '24px',
      marginBottom: '0px'
    },
  },
  text2: {
    "@media (max-width:500px)": {
      fontSize: "15px",
    },
  },
});

const HeaderMenuDDI = ({ link, ddi }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [width] = useWindowSize();
  let hideddi = false;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <C2CDialog
        open={open}
        onClose={handleClose}
      />
      <Container disableGutters maxWidth="false" sx={{ justifyContent: 'center', display: 'flex', boxShadow: '0px 3px 6px #00000029' }}>
        <Grid container xs={11} sm={10} justifyContent="space-between" >
          <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Link to={link} >
              <img width='300px' height="47px" src={Logo} alt="logo" className={classes.logo} />
            </Link>
          </Grid>
          <Grid item xs={5} sm={2} sx={{ display: { xs: 'block', md: 'none' } }}>
            <Link to={link} >
              <img width='120px' height="50px" src={LogoMobile} alt="logo" className={classes.logo} />
            </Link>
          </Grid>
          {!isResponsive(width) && !isResponsiveTablet(width) &&
            <Grid item sm={7}>
              <Menu />
            </Grid>
          }
          <Grid item xs={4.1} sm={2.5}>
            <Button className={hideddi === true ? classes.buttonHidden : classes.button}
              onClick={() => {
                if (isResponsive(width)) {
                  dial(ddi);
                } else {
                  handleClickOpen();
                }
              }}
              sx={{
                background: '#FF8200 0% 0% no-repeat padding-box',
                borderRadius: '10px',
                marginTop: '13px',
                height: '55px',
                width: '100%',
                paddingLeft: '0px',
                paddingRight: '0px',
              }}>
              <Stack sx={{ marginTop: '0px' }} spacing={-1.5}>
                <Typography color="white" variant="body5" className={classes.text1}>LLAMA AHORA</Typography>
                <Typography color="white" variant="subtitle1" className={classes.text2}>{ddi}</Typography>
              </Stack>
            </Button>
          </Grid>

          {(isResponsive(width) || isResponsiveTablet(width)) &&
            <Grid item >
              <HamburgerMenu ddi={ddi} />
            </Grid>
          }
        </Grid>
      </Container>
    </>
  );
};

export default HeaderMenuDDI;
