import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography, Box, Button, TextField, FormControlLabel, Checkbox, Grid } from '@mui/material';
import { useLocation } from "react-router-dom";
import { isPhoneValid } from '../utils/Functions';
import CallingDialog from '../dialog/CallingDialog';
import { launchC2C } from '../utils/PostLead';
import PrivacyDialog from '../dialog/PrivacyDialog';
import MuiLink  from '@mui/material/Link';

const useStyles = makeStyles({
  form: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    marginTop: '0%',
    display:'flex',
    maxWidth:'530px',
    flexDirection:'column',
    justifyContent:'center',
    marginLeft: '5%',
    marginRight: '5%',
    '@media (max-width:900px)': {
      justifyContent:'flex-start',
      position:'relative',
      marginTop: '2%',
      marginLeft: '0%',
      marginRight: '0%',
    },
    '@media (max-width:600px)': {
      marginTop: '5%',
      
    },

  },
  textField: {
    width: '100%',
    marginTop: '10px',
    borderRadius: '10px',
    '& .MuiInputLabel-root .MuiTypography-root': {
      marginTop: '0px',
    },
    '& .MuiOutlinedInput-input': {
      color: 'white',
      '@media (max-width:900px)': {
        color:'#041E42',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
        '@media (max-width:900px)': {
          borderColor:'#041E42',
          borderRadius:'10px',
        },
      },
      '&:hover fieldset': {
        borderColor: 'white',
        '@media (max-width:900px)': {
          borderColor:'#041E42',
        },
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
        '@media (max-width:900px)': {
          borderColor:'#041E42',
        },
      },
    },
  },
  checkbox: {
    '&.MuiCheckbox-root': {
      padding:'2px 9px',
      color: 'white',
      '@media (max-width:900px)': {
        color:'#041E42',
      },
    }
  },
  button: {
    '&:hover': {
      backgroundColor: '#051E42',
      '@media (max-width:900px)': {
        backgroundColor:'#041E42',
      },
    },
  },
});

function BannerImage({ imageDesktop, imageMobile }) {
  const classes = useStyles();
  const { search } = useLocation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isOpenCallingDialog, setIsOpenCallingDialog] = useState(false);
	const [isOpenPrivacyDialog, setIsOpenPrivacyDialog] = useState(false);

  const handleClose = () => {
    setChecked(false);
    clearPhone();
    setButtonClicked(false);
  };
  const clearPhone = () => { 
    setPhoneNumber("");
    setChecked(true);
    setButtonClicked(false);
  }

  const handleCloseCallingDialog = () => {
    setIsOpenCallingDialog(false);
    setButtonClicked(false);
    handleClose();
  };

  const handleClosePrivacyDialog = () => {
    setIsOpenPrivacyDialog(false);
  };

  const handleCheckboxChange = (event) => {
    setChecked(!checked);
  }

  const handleTextFieldChange = (event) => {
    setPhoneNumber(event.target.value);
  }

  const handleClickOpen = () => {
    setIsOpenCallingDialog(true);
  };

  const handleClicPrivacy = () => {
    setIsOpenPrivacyDialog(true);
  };

  return (
    <>
      <CallingDialog
        open={isOpenCallingDialog}
        onClose={handleCloseCallingDialog}
      />
      <PrivacyDialog
				open={isOpenPrivacyDialog}
				onClose={handleClosePrivacyDialog}
			/>
      <Container disableGutters maxWidth="false" sx={{justifyContent:'center', display:'flex'}} >
        <Grid container maxWidth="false"  xs={10.5} sm={10} sx={{ position: 'relative', marginTop: '70px', justifyContent:'center'  }}>
            <Grid sx={{display:{xs:'none', md:'block'}, '& img':{
                height:{md:'580px', xl:'auto'}
              }
            }}>
              <picture>
                <source media="(max-width: 500px)" srcSet={imageMobile} />
                <img style={{ objectFit: 'cover', width: '100%', borderRadius:'37px' }} src={imageDesktop} alt="banner"/>
              </picture>
            </Grid>
            <Grid xs={12} sx={{display:{xs:'block', md:'none'}}}>
              <picture>
                <img  width="100%" style={{}} src={imageMobile} alt="banner"/>
              </picture>
            </Grid>
          <Box className={classes.form}>
            <Typography variant="h3" color="white" sx={{ color:{xs:'#041E42', md:'white'} }}>¿Buscas la tarifa que se adapte a tus necesidades?</Typography>
            <Typography variant="body2" color="white" sx={{marginTop: '10px', color:{xs:'#041E42', md:'white'} }}>No somos una comercializadora más, personalizamos cada uno de nuestros productos a tus necesidades. Nos importa tu ahorro.</Typography>
            <Typography variant="body2" color="white" sx={{marginTop: '20px', color:{xs:'#041E42', md:'white'} }}>Indícanos tu número de teléfono y buscamos la mejor tarifa para ti.</Typography>
            <Typography className={classes.stackTimetable} sx={{marginTop: '10px', color:{xs:'#041E42', md:'white'} }} color="white" variant="body2">Nuestro horario es de lunes a viernes de 9 a 21h</Typography>
            <TextField value={phoneNumber}
              onChange={handleTextFieldChange}
              className={classes.textField}
              label={<Typography sx={{ marginTop: '0px', color:{xs:'#041E42', md:'white'} }} variant="body2" color="white">Escribe tu teléfono</Typography>} variant="outlined" />
            <FormControlLabel
              sx={{ marginTop: '5px', marginBottom: '5px', alignItems:'self-start'  }}
              control={<Checkbox className={classes.checkbox} onChange={handleCheckboxChange} />}
              label={<Typography
                variant="subtitle2" color="white" sx={{lineHeight: '1rem', color:{xs:'#041E42', md:'white'}}}>He leído y acepto la
                  <MuiLink 
                    onClick={() => {
                      handleClicPrivacy();
                    }}                 
                  style={{ textDecoration: 'none' }}> política de privacidad </MuiLink>
                y consiento el tratamiento de mis datos personales para todas las finalidades contenidas en la misma</Typography>
              } />
            {!checked && buttonClicked && <Typography variant="subtitle2" color="white">Debes aceptar la política de privacidad</Typography>}
            {checked && buttonClicked && !isPhoneValid(phoneNumber) && <Typography variant="subtitle2" color="white">El número de teléfono no es válido</Typography>}
            <Button className={classes.button}
              onClick={() => {
                setButtonClicked(true);
                if (checked && isPhoneValid(phoneNumber)) {
                  launchC2C(phoneNumber);
                  handleClickOpen();
                }
              }}
              sx={{
                background: '#FF8200 0% 0% no-repeat padding-box',
                borderRadius: '10px',
                height: { xs:'50px', md:'70px'},
                width: '100%'
              }}>
              <Typography color="white" variant="subtitle1">¡TE LLAMAMOS!</Typography>
            </Button>
          </Box>
        </Grid>
      </Container>
  </>
  )
}

export default BannerImage;