import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ColoredCheckbox from "../checkbox/ColoredCheckbox";
import PrivacyDialog from "../dialog/PrivacyDialog";
import { isPhoneValid } from "../utils/Functions";
import { launchC2C } from "../utils/PostLead";
import CallingDialog from "./CallingDialog";

const useStyles = makeStyles({
  button: {
    marginTop: "5px",
    "&:hover": {
      backgroundColor: "#F39D0A",
    },
    "@media (max-width:1200px)": {
      marginBottom: "7%",
    },
  },
});

function C2CDialog(props) {
  const classes = useStyles();
  const { search } = useLocation();
  const { onClose, open } = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isOpenCallingDialog, setIsOpenCallingDialog] = useState(false);
  const [isOpenPrivacyDialog, setIsOpenPrivacyDialog] = useState(false);

  const handleClose = () => {
    setChecked(false);
    setPhoneNumber("");
    setButtonClicked(false);
    onClose();
  };

  const handleClosePrivacyDialog = () => {
    setIsOpenPrivacyDialog(false);
  };

  const handleCloseCallingDialog = () => {
    setIsOpenCallingDialog(false);
    setButtonClicked(false);
    handleClose();
  };

  const handleCheckboxChange = (event) => {
    setChecked(!checked);
  };

  const handleTextFieldChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleClickOpen = () => {
    setIsOpenCallingDialog(true);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  const handleClicPrivacy = () => {
    setIsOpenPrivacyDialog(true);
  };

  return (
    <>
      <CallingDialog
        open={isOpenCallingDialog}
        onClose={handleCloseCallingDialog}
      />
      <PrivacyDialog
        open={isOpenPrivacyDialog}
        onClose={handleClosePrivacyDialog}
      />
      <Dialog onClose={handleClose} open={open}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: { xs: 0, md: 8 },
            top: { xs: 0, md: 8 },
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            px: { xs: "25px", md: "24px" },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontSize: { xs: "1.1rem", md: "1.1875rem" } }}
          >
            ¿Hablamos? Te llamamos gratis
          </Typography>
        </DialogTitle>
        <Divider />
        <Box sx={{ margin: "25px" }}>
          <Typography className={classes.stackTimetable} variant="body2">
            Nuestro horario es de lunes a viernes de 9 a 21h
          </Typography>
          <TextField
            inputProps={{ style: { fontSize: 18, padding: "10px" } }}
            onChange={handleTextFieldChange}
            sx={{
              width: { xs: "100%", md: "80%" },
              marginTop: "10px",
              padding: "10px 0px",
            }}
            label={
              <Typography
                sx={{
                  marginTop: "-5px",
                  padding: "10px 5px",
                  textDecoration: "none",
                }}
                variant="body2"
              >
                Escribe tu teléfono
              </Typography>
            }
            variant="outlined"
          />
          <FormControlLabel
            sx={{
              marginTop: "4px",
              marginBottom: "4px",
              alignItems: "self-start",
            }}
            control={<ColoredCheckbox onChange={handleCheckboxChange} />}
            label={
              <Typography variant="subtitle2">
                He leído y acepto la{" "}
                <MuiLink
                  onClick={() => {
                    handleClicPrivacy();
                  }}
                  style={{ textDecoration: "none", color: "#FF6200" }}
                >
                  política de privacidad
                </MuiLink>{" "}
                y consiento el tratamiento de mis datos personales para todas
                las finalidades contenidas en la misma
              </Typography>
            }
          />
          {!checked && buttonClicked && (
            <Typography variant="subtitle2" color="red">
              Debes aceptar la política de privacidad
            </Typography>
          )}
          {checked && buttonClicked && !isPhoneValid(phoneNumber) && (
            <Typography variant="subtitle2" color="red">
              El número de teléfono no es válido
            </Typography>
          )}
          <Button
            className={classes.button}
            onClick={() => {
              setButtonClicked(true);
              if (checked && isPhoneValid(phoneNumber)) {
                launchC2C(phoneNumber);
                handleClickOpen();
              }
            }}
            sx={{
              background: "#FF8200 0% 0% no-repeat padding-box",
              borderRadius: "10px",
              marginTop: "auto",
              height: "40px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography color="white" variant="body3">
              LLAMADME GRATIS
            </Typography>
          </Button>
        </Box>
      </Dialog>
    </>
  );
}

export default C2CDialog;
