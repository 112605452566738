import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Header from '../header/Header';
import Banner from '../banner/Banner';
import Footer from '../footer/Footer';
import Cards from '../card/Cards';
import Heading from '../heading/Heading';
import Questions from '../questions/Questions';
import StickyDDIMobile from '../button/StickyDDIMobile';

function Tarifas() {
  return (
    <>
      <Stack>
        <StickyDDIMobile />
        <Container disableGutters>
          <Header />
        </Container>
        <Container maxWidth="false" disableGutters>
          <Banner />
        </Container>
        <Container maxWidth="xl">
          <Heading text="¿Quieres saber por qué tienes que contratar aquí luz y gas?" />
          <Cards />
        </Container>
        <Container maxWidth="xl">
          <Heading text="¡Resuelve tus dudas y comienza a ahorrar en tu factura de luz y gas!" />
          <Questions />
        </Container>
      </Stack>
      <Footer />
    </>
  );
}

export default Tarifas;
