import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardMedia, Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  media: {
    width: '45%',
    height: -1,
    marginTop: '7%',
    marginLeft: '27%',
  }
});

function HappyCard({image, title, description1}) {
  const classes = useStyles();

  return (
    <Card sx={{ maxWidth: 320,
      background: '#051E42 0% 0% no-repeat padding-box', 
      boxShadow: 'inset 2px 2px 10px #FFFFFFE6, 0px 3px 6px #00000029',
      borderRadius: '10px',
      minHeight: { xs: '0px', sm: '570px', md: '580px', lg: '530px', xl: '510px'} }}>
      <CardContent>
        <Box sx={{ textAlign: 'center', minHeight: { md: '40px', lg: '140px'} }}>
          <CardMedia className={classes.media} component="img" image={image} />
        </Box>
        <Box sx={{ textAlign: 'center', minHeight: '40px'}}>
          <Typography sx={{ marginTop: '10%', lineHeight: '1.3'}} variant="subtitle1" color="common.white">
            {title}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ marginTop: '21%', mb: 1.5 }} color="common.white">
          {description1}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default HappyCard;