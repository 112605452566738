import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
  subtitle1: {
    fontSize: '1.1875rem',
    fontFamily: 'Galano Grotesque, Arial',
    '@media (min-width:1200px)': {
      fontSize: '1.4286rem'
    },
    '@media (min-width:900px) and (max-width:1500px)': {
      fontSize: '1.2857rem!important'
    },
  },
});

function Question({ question, answer }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return(
    <>
      <Grid item xs={12}  sm container  onClick={() => setOpen(!open)}>
        <Grid item xs>
          <Typography className={classes.subtitle1} variant="h3" sx={{ display: 'inline-block', fontWeight:'600' }} color="#051E42">{question}</Typography>
        </Grid>
        <Grid item>
          <IconButton size="small" sx={{ background: '#FF8200 0% 0% no-repeat padding-box',
            borderRadius: '10px',
            ':hover':{
              background: '#041E42',
            }
            }}
            aria-label="show">
            {!open && <KeyboardArrowDownIcon style={{ fill: "white", fontSize:'2rem'}} />}
            {open && <KeyboardArrowUpIcon style={{ fill: "white", fontSize:'2rem'}} />}
          </IconButton>
        </Grid>
      </Grid>
      {open && <Typography variant="body2" color="#051E42">{answer}</Typography>}
    </>
  )
}

export default Question;