import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import C2CDialog from "../dialog/C2CDialog";
import { dial, isResponsive } from "../utils/Functions";
import useWindowSize from "../utils/WindowSize";

const useStyles = makeStyles({
  form: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "100%",
    marginTop: "0%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "3%",
    width: "45%",
    "@media (max-width:1500px)": {
      width: "55%",
    },
    "@media (max-width:900px)": {
      justifyContent: "flex-start",
      position: "relative",
      marginTop: "2%",
      marginLeft: "0%",
      width: "100%",
    },
    "@media (max-width:800px)": {
      marginTop: "10%",
    },
  },
  textField: {
    width: "100%",
    marginTop: "10px",
    borderRadius: "10px",
    "& .MuiOutlinedInput-input": {
      color: "white",
      "@media (max-width:900px)": {
        color: "#041E42",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        "@media (max-width:900px)": {
          borderColor: "#041E42",
        },
      },
      "&:hover fieldset": {
        borderColor: "white",
        "@media (max-width:900px)": {
          borderColor: "#041E42",
        },
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
        "@media (max-width:900px)": {
          borderColor: "#041E42",
        },
      },
    },
  },
  checkbox: {
    "&.MuiCheckbox-root": {
      color: "white",
    },
  },
  button: {
    "&:hover": {
      backgroundColor: "#051E42",
    },
  },
});

function BannerImageTextReversedLuzyGas({ imageDesktop, imageMobile, ddi }) {
  const bullets = [
    "Mismo precio durante 5 años.",
    "El precio de kWh es fijo, suba o no la luz.",
    "Sin permanencia ni personalización.",
  ];

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [width] = useWindowSize();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <C2CDialog open={open} onClose={handleClose} />
      <Container
        disableGutters
        maxWidth="false"
        sx={{ justifyContent: "center", display: "flex" }}
      >
        <Grid
          container
          xs={10.5}
          sm={10}
          sx={{ position: "relative", marginTop: "70px" }}
        >
          <Grid
            sx={{
              display: { xs: "none", md: "block" },
              "& img": {
                height: { md: "580px", xl: "auto" },
              },
            }}
          >
            <picture>
              <source media="(max-width: 500px)" srcSet={imageMobile} />
              <img
                style={{
                  objectFit: "cover",
                  width: "100%",
                  borderRadius: "37px",
                }}
                src={imageDesktop}
                alt="banner"
              />
            </picture>
          </Grid>
          {/*<Grid xs={12} sx={{ display: { xs: "block", md: "none" } }}>
            <picture>
              <img width="100%" style={{}} src={imageMobile} alt="banner" />
            </picture>
          </Grid>*/}
          <Box
            className={classes.form}
            sx={{
              "@media (max-width:900px)": {
                background: `url(${imageMobile})`,
                backgroundSize: "cover",
                padding: "5% 5% 200px",
                borderRadius: "20px",
                margin: "0 !important",
              },
            }}
          >
            <Typography
              variant="h3"
              color="white"
              sx={{
                fontFamily: "Galano Grotesque Bold, Arial",
                marginTop: { xs: "10px" },
                marginBottom: { xs: "10px", md: "0" },
                color: { xs: "#FFFFFF" },
                fontSize: { xs: "1.4rem", md: "1.7rem" },
              }}
            >
              Despreocúpate de la luz
            </Typography>
            {/*<Typography
              variant="h5"
              color="white"
              sx={{
                fontFamily: "Galano Grotesque Bold, Arial",
                marginTop: "10px",
                color: { xs: "#041E42", md: "white" },
              }}
            >
              Recomiéndanos a tus amigos y recibirás hasta 80€ por cada uno que
              contrate luz y/o gas. ¡Trae a los que quieras!
            </Typography>*/}
            <List sx={{ width: { xs: "100%" } }}>
              {bullets.map((bullet, i) => (
                <ListItem
                  key={i}
                  sx={{
                    marginLeft: "4px",
                    textAlign: "left",
                    color: "#464646",
                    fontFamily: "Roboto, Arial",
                    fontWeight: "300",
                    gap: "8px",
                    padding: "8px 16px 8px 0px",
                    alignItems: { md: "flex-end" },
                    "@media (max-width:900px)": {},
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.908"
                    height="14.846"
                    viewBox="0 0 19.908 14.846"
                  >
                    <path
                      id="Icon_awesome-check"
                      data-name="Icon awesome-check"
                      d="M6.762,19.131l-6.47-6.47a1,1,0,0,1,0-1.408L1.7,9.846a1,1,0,0,1,1.408,0L7.466,14.2,16.8,4.869a1,1,0,0,1,1.408,0l1.408,1.408a1,1,0,0,1,0,1.408L8.169,19.131A1,1,0,0,1,6.762,19.131Z"
                      transform="translate(0 -4.577)"
                      fill="#FFFFFF"
                    />
                  </svg>
                  <Typography
                    variant="h5"
                    color="white"
                    sx={{
                      fontFamily: "Galano Grotesque Bold, Arial",
                      fontSize: { xs: "16px", md: "22px" },
                      color: { xs: "#FFFFFF" },
                    }}
                  >
                    {bullet}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <Button
              className={classes.button}
              onClick={() => {
                if (isResponsive(width)) {
                  dial(ddi);
                } else {
                  handleClickOpen();
                }
              }}
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                background: "#FF8200 0% 0% no-repeat padding-box",
                borderRadius: "10px",
                height: { xs: "50px", md: "70px" },
                width: "100%",
                maxWidth: "320px",
              }}
            >
              <Typography color="white" variant="subtitle1">
                LLÁMAME GRATIS
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Container>
    </>
  );
}

export default BannerImageTextReversedLuzyGas;
