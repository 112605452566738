import * as React from 'react';
import Grid from '@mui/material/Grid';
import ComplexCardGas from './luz/ComplexCardGas';

const ComplexCardsGasMobile = () => {
    return (
    <Grid sx={{ marginTop: '20px' }} container justifyContent="center" alignItems="stretch">
        <Grid container xs={10.5} md={10} spacing={{md:2}}  sx={{justifyContent:{ xs:'center', xl:'space-between'}}}  justifyContent="space-between">
            <Grid item xs={12} md={3.5} sx={{display:'flex', justifyContent:'center'}}>
                <ComplexCardGas suptitle="Es el momento de empezar a ahorrar" title="Tarifa Gas +" ctaText="MÁS INFO" descriptionBold1="Precio fijo," description1="sin cortes en el suministro y con la máxima tranquilidad" description2="Paga solo por lo que consumes y disfruta del servicio de" descriptionBold3=" mantenimiento de electrodomésticos GRATIS"/>
            </Grid>
            <Grid item md={8.5} sx={{ display:{xs:'none', md:'block'}, height: {xs: '439px', sm:'auto'}, '& img':{borderRadius:'37px'} }}>
                <picture>
                    <img width="100%" height="100%" src={require('../assets/images/banner-cards-gas.png')} alt="banner"  style={{display: 'block', position: 'relative', objectFit: 'cover', objectPosition: 'left'}} />
                </picture>
            </Grid>
        </Grid>
    </Grid>
    );
}

export default ComplexCardsGasMobile;