import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import C2CDialog from '../dialog/C2CDialog';

const useStyles = makeStyles({
  image: {
    display: 'none',
    '@media (max-width:600px)': {
      display: 'block'
    },
  },
});

function BannerImageMobile({ image }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <C2CDialog
        open={open}
        onClose={handleClose}
      />
      <Box className={classes.image} sx={{ position: 'relative', marginTop: '70px' }}>
        <picture>
          <img width="100%" height="auto" src={image} alt="banner" onClick={() => handleClickOpen()} />
        </picture>
      </Box>
    </>
  )
}

export default BannerImageMobile;