import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Logo from '../assets/images/logo.svg';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import useWindowSize from '../utils/WindowSize';
import C2CDialog from '../dialog/C2CDialog';
import { isResponsive, dial } from '../utils/Functions';

const useStyles = makeStyles({
  button: {
    paddingLeft: '15px',
    paddingRight: '15px',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: '#F39D0A',
    }
  },
  buttonHidden: {
    visibility: 'hidden'
  },
  logo: {
    marginTop: "20px",
    marginBottom: "15px",
    height: "35px",
    marginLeft: "3%",
  },
});

const HeaderDDI = ({ link, ddi }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [width] = useWindowSize();
  let hideddi = false;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <C2CDialog open={open} onClose={handleClose} />
      <Grid container alignItems="start">
        <Grid item xs={6}>
          <Link to={link} >
            <img width='181px' height="470px" src={Logo} alt="logo" className={classes.logo} />
          </Link>
        </Grid>

        <Grid item xs={4} className={classes.right}>
          <Button className={hideddi === true ? classes.buttonHidden : classes.button}
            onClick={() => {
              if (isResponsive(width)) {
                dial(ddi);
              } else {
                handleClickOpen();
              }
            }}
            sx={{
              background: '#FF8200 0% 0% no-repeat padding-box',
              borderRadius: '10px',
              height: '55px',
              width: '100%',
              marginTop: '13px',
              paddingLeft: '0px!important',
              paddingRight: '0px!important',
            }}>
            <Typography color="white" variant="body5" sx={{ fontSize: '11px', lineHeight: '24px', marginBottom: '0px' }}>LLAMA AHORA</Typography>
            <Typography color="white" variant="subtitle1" sx={{ fontSize: '15px', marginTop: '-12px', }}>{ddi}</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default HeaderDDI;
