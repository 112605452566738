import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        padding:'2px 9px',
      "&$checked": {
        color: "#ff6200",
      }
    },
    checked: {}
  });

function ColoredCheckbox({ onChange }) {
    const classes = useStyles();

    return (
        <Checkbox classes={{
            root: classes.root,
            checked: classes.checked
          }} onChange={onChange}/>
    )
}

export default ColoredCheckbox;