import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Container, Typography, Box, Button, Checkbox, TextField, FormControlLabel } from '@mui/material';
import C2CDialog from '../dialog/C2CDialog';
import { isResponsive, dial } from '../utils/Functions';
import useWindowSize from '../utils/WindowSize';


const useStyles = makeStyles({
  form: {
    position: 'absolute',
    top: '0',
    right: '0',
    height:'100%',
    marginTop: '0%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    marginRight: '10%',
    '@media (max-width:900px)': {
      justifyContent:'flex-start',
      position:'relative',
      marginTop: '2%',
      marginRight: '0%',
    },
    '@media (max-width:800px)': {
      marginTop: '10%',
    },
  },
  textField: {
    width: '100%',
    marginTop: '10px',
    borderRadius: '10px',
    '& .MuiOutlinedInput-input': {
      color: 'white',
      '@media (max-width:900px)': {
        color:'#041E42',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
        '@media (max-width:900px)': {
          borderColor:'#041E42',
        },
      },
      '&:hover fieldset': {
        borderColor: 'white',
        '@media (max-width:900px)': {
          borderColor:'#041E42',
        },
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
        '@media (max-width:900px)': {
          borderColor:'#041E42',
        },
      },
    },
  },
  checkbox: {
    '&.MuiCheckbox-root': {
      color: 'white',
    }
  },
  button: {
    '&:hover': {
      backgroundColor: '#051E42',
    },
  },
});

function BannerImageTextReversed({ imageDesktop, imageMobile, ddi }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [width] = useWindowSize();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };


  return (
    <>
      <C2CDialog
        open={open}
        onClose={handleClose}
      />
      <Container disableGutters maxWidth="false" sx={{justifyContent:'center', display:'flex'}} >
        <Grid container xs={10.5} sm={10} sx={{ position: 'relative', marginTop: '70px' }}>
          <Grid sx={{display:{xs:'none', md:'block'}, '& img':{
                height:{md:'580px', xl:'auto'}
              }}}>
            <picture>
              <source media="(max-width: 500px)" srcSet={imageMobile} />
              <img style={{ objectFit: 'cover', width: '100%', borderRadius:'37px'}} src={imageDesktop} alt="banner"/>
            </picture>
          </Grid>
          <Grid xs={12} sx={{display:{xs:'block', md:'none'}}}>
              <picture>
                <img  width="100%" style={{}} src={imageMobile} alt="banner"/>
              </picture>
          </Grid>          
          <Box className={classes.form}>
            <Typography variant="h3" color="white" sx={{color:{xs:'#041E42', md:'white'}}}>Batería virtual: tu factura a 0€</Typography>
            <Typography variant="h5" color="white" sx={{fontFamily: 'Galano Grotesque Bold, Arial', marginTop: '10px', color:{xs:'#041E42', md:'white'}}}>¡El ahorro está a tu alcance!</Typography>
            <Typography variant="body2" color="white" sx={{marginTop: '20px', color:{xs:'#041E42', md:'white'}}}>Deja tu factura a 0€, incluso en tu segunda vivienda.</Typography>
            <Typography variant="body2" color="white" sx={{marginTop: '5px', color:{xs:'#041E42', md:'white'}}}>Decide todo sobre tu ahorro por 1,99€ al mes</Typography>
            <Typography variant="h5" color="white" sx={{fontFamily: 'Galano Grotesque Bold, Arial', marginTop: '20px', color:{xs:'#041E42', md:'white'}}}>y disfruta de los 2 primeros meses gratis</Typography>
            <Button className={classes.button}
              onClick={() => {
                if (isResponsive(width)) {
                  dial(ddi);
                } else {
                  handleClickOpen();
                }
              }}
              sx={{
                marginTop: '40px',
                background: '#FF8200 0% 0% no-repeat padding-box',
                borderRadius: '10px',
                height: { xs:'50px', md:'70px'},
                width: '100%'
              }}>
              <Typography color="white" variant="subtitle1">¡MÁS INFO!</Typography>
            </Button>
          </Box>
        </Grid>
      </Container>
    </>
  )
}

export default BannerImageTextReversed;