import React from 'react';
import Container from '@mui/material/Container';

function StickyContainer({ children }) {
  return (
    <Container maxWidth="false" disableGutters sx={{ position: 'sticky', top: '0px', zIndex: '1100', background: 'white' }}>
      {children}
    </Container>
  );
}

export default StickyContainer;