import React from 'react';
import {  Typography,  Stack } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  stackHeaderMobile: {
    '@media (max-width:500px)': {
      marginBottom:'20px',
      marginTop:'10px',
      '& h2': {
        fontSize:'35px',
        lineHeight:'28px',
        marginTop:'5px'
      },
      '& h4':{
        fontSize:'17px',
        lineHeight:'22px',
        marginTop:'7px'
      }
    },
  },

});

function TextHeaderLuz({ titleMobile, titleMobile2, titleMobile3, props}) {
  const classes = useStyles(props);
  return (
        <Stack className={classes.stackHeaderMobile} sx={{display:{md:'none'}, textAlign:'left'}}>
          <Typography variant="h2">{titleMobile2}</Typography>
          <Typography variant="h4">{titleMobile3}</Typography>
        </Stack>
  )
}

export default TextHeaderLuz;