import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


function CallingDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose}  open={open}>

        <DialogContent sx={{padding: '10% 4%', textAlign: 'center', zIndex:3}}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'black',
              zIndex:'4'
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContentText sx={{zIndex:3}} id="alert-dialog-description">
            <Typography sx={{ marginTop: '0px' }} variant="body2">¡Muchas gracias! Nos pondremos en contacto lo antes posible.</Typography>
          </DialogContentText>
        </DialogContent>
    </Dialog>
  );
}

export default CallingDialog;
