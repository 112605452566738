import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
  headings:{
    '& h2':{
      fontSize:'200px',
      lineHeight:'180px',
      margin:0,
      fontFamily:'Galano Grotesque Bold',
      '@media (max-width:600px)': {
        fontSize:'80px',
        lineHeight:'80px',
        margin:0,
      },
    },
    '& h3':{
      fontSize:'43px',
      fontFamily:'Galano Grotesque Bold',
      lineHeight:'43px',
      marginBottom:'0rem',
      '@media (max-width:600px)': {
        fontSize:'25px',
        lineHeight:'30px',
      },
    },
    '& h4':{
      fontSize:'25px',
      lineHeight:'30px',
      '@media (max-width:600px)': {
        fontSize:'16px',
      },
    },
  },
  dialog:{
    '& .MuiPaper-root':{
      borderRadius:'50px'
    },
    '& .MuiSvgIcon-root':{
      fontSize:'2.5rem'
    }
  }

});


function CallingDialogCalculadora(props) {
  const { onClose, open, mensajeAhorro } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog className={classes.dialog}  maxWidth="xl" onClose={handleClose}  open={open}>

        <DialogContent sx={{padding: '10% 4%',  textAlign: 'center', width:{md:'810px'}, zIndex:3}}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 18,
            top: 12,
            color: '#041E42',
            zIndex:'4'
          }}
        >
          <CloseIcon />
        </IconButton>
          {/*<CircularProgress sx={{color: '#FF6200'}} />*/}
          <DialogContentText sx={{zIndex:3}} id="alert-dialog-description">
            <Typography className={classes.headings} sx={{ marginTop: '0px', color:'#041E42' }} dangerouslySetInnerHTML={{__html:mensajeAhorro}}/>
          </DialogContentText>
        </DialogContent>
    </Dialog>
  );
}

export default CallingDialogCalculadora;
