import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router';
import '../animation.css';

const useStyles = makeStyles({
  linkSelected: {
    textDecorationColor: '#FFB834',
    textDecorationThickness: '3px',
    '& :hover':{
      color:'red',
    }
  },
  linkNotSelected: {
    textDecoration: 'none',
  }
});

const getVariant = (path, currentPath) => {
  return path === currentPath ? "body3" : "body4";
};

function Menu() {
  const classes = useStyles();

  const location = useLocation();
  const { search } = useLocation();

  return (
    <>
      <Grid
        justifyContent="center"
        alignItems="center"
        direction="row"
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        sx={{ flexGrow: 1, marginTop: 3, marginRight: '30px', flexDirection: "row" }}
      >
        <Grid item>
            <Link className={"/luz" === location.pathname ? "underlineLink underlineLinkSelected" : "underlineLink"} sx={{fontWeight:700, textDecoration:'none'}} href={`/luz${search}`} variant={getVariant("/luz", location.pathname)} color="#051E42">
              LUZ
            </Link>
        </Grid>
        <Grid item>
            <Link sx={{fontWeight:700, textDecoration:'none'}}  className={"/luzygas" === location.pathname ? "underlineLink underlineLinkSelected" : "underlineLink"} href={`/luzygas${search}`} variant={getVariant("/luzygas", location.pathname)} color="#051E42">
              LUZ Y GAS
            </Link>
        </Grid>
        <Grid item>
            <Link className={"/gas" === location.pathname ? "underlineLink underlineLinkSelected" : "underlineLink"}   sx={{fontWeight:700, textDecoration:'none'}}  href={`/gas${search}`} variant={getVariant("/gas", location.pathname)} color="#051E42">
              GAS
            </Link>
        </Grid>
      </Grid>
    </>
  );
}

export default Menu;