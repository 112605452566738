import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
    anchor: {
        marginTop: '40px',
        marginBottom:'40px',
        textAlign: 'center',
        display: 'none',
        textDecorationColor: '#FF6200',
        '@media (max-width:600px)': {
            display: 'block'
        },
    },
});

function MobileAnchor() {
    const classes = useStyles();

    return (
        <a
            href="/"
            onClick={e => {
                let tarifas = document.getElementById("tarifas");
                e.preventDefault();  // Stop Page Reloading
                tarifas && tarifas.scrollIntoView();
            }}
            className={classes.anchor}
        >
            <Typography variant="body3" color="#FF6200">VER TARIFAS</Typography>
        </a>

    );
}

export default MobileAnchor;