import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { launchC2C } from '../utils/PostLead';
import { isPhoneValid } from '../utils/Functions';
import CallingDialog from '../dialog/CallingDialog'
import PrivacyDialog from '../dialog/PrivacyDialog';    
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';


const StyledStack = styled (Stack)({
position: 'fixed',
width: '100%',
height: '120px',
bottom: '0',
marginBottom: '-70px',
/*maxWidth: '1300px',*/
left: '50%',
right: '50%',
transform: 'translate(-50%, -50%)',
textAlign: 'center',
borderRadius: '10px',
display: 'flex',
zIndex: '1110',
'&.show': {
  transform: 'translate(-50%, -50%)',
  transition: 'transform 0.8s ease-in-out',  // Bring the component back to its original position
  visibility: 'visible', // Make the component visible
  opacity: '1', // Make it fully opaque
},
'&.hide': {
  transform: 'translate(-50%, 50%)', // Move the component to the bottom
  transition: 'transform 0.8s ease-in-out', 
  visibility: 'visible', // Hide the component
  opacity: '1', // Make it fully transparent
},

'@media (max-width:1200px)': {
  width: '100%',
},
'@media (max-width:900px)': {
  display: 'none',
},
});
const StyledPaper = styled(Paper)({
  position: 'fixed',
  width: '100%',
  height: '150px',
  bottom: '0',
  marginBottom: '-70px',
  /*maxWidth: '1300px',*/
  left: '50%',
  right: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  borderRadius: '10px',
  display: 'flex',
  zIndex: '1110',
  paddingBottom:'15px',
  backgroundColor: '#041E42',
  '@media (max-width:1200px)': {
    width: '100%',
  },
  '@media (max-width:900px)': {
    display: 'none',
  },
});

const StyledPaperSup = styled(Paper)({
  position: 'fixed',
  justifyContent: 'center',
  alignItems:'center',
  width: '400px',
  bottom: '0',
  marginBottom: '105px',
  maxWidth: '1000px',
  left: '50%',
  right: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  borderRadius: '10px',
  display: 'flex',
  zIndex: '1111',
  backgroundColor: 'white',
  color:'#041E42',
  '@media (max-width:900px)': {
    display: 'none',
  },
});

export const TextFieldWrapper = styled(TextField)({
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor:'white!important',
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      borderColor:'white!important',
      transform: 'translate(14px, 10px) scale(1)',
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiFormLabel-filled': {
      borderColor:'white!important',
      transform: 'translate(14px, -7px) scale(0.75)',
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      height: '0.5em',
      borderRadius:'5px',
    },
  fieldset: {
    borderRadius: '3px',
    borderWidth: '0px',
    backgroundColor: '#FFFFFF',
    opacity: '0.5',
    '& .Mui-focused': {
      bordeColor: 'white',
    },
  },


})


const StickyC2CBottom = () => {

  const [open, setOpen] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isOpenCallingDialog, setIsOpenCallingDialog] = useState(false);
  const [isOpenPrivacyDialog, setIsOpenPrivacyDialog] = useState(false);
  const [visibility, setVisibility] = useState(true);

  const handleClose = (value) => {
    setOpen(false);
  };
  const handleClosePrivacyDialog = () => {
    setIsOpenPrivacyDialog(false);
  };
  const handleCheckboxChange = (event) => {
    setChecked(!checked);
  }
  const handleTextFieldChange = (event) => {
    setPhoneNumber(event.target.value);
  }
  const handleHide = () => {
    setVisibility(!visibility);
  };
  
  const handleClickOpen = () => {
    setIsOpenCallingDialog(true);
    setTimeout(() => {
      setIsOpenCallingDialog(false);
    }, 3000);
  };
  const handleClicPrivacy = () => {
    setIsOpenPrivacyDialog(true);
  };
  const isNumeric = (str) => /^\d*$/.test(str);
  const [val, setVal] = useState("");
  
  const onInputChange = (e) => {
    const { value } = e.target;
    if (isNumeric(value)) {
      setVal(value);
    }
  };

  return (
    <>
      <CallingDialog
        open={isOpenCallingDialog}
        onClose={handleClose}
      />
      <PrivacyDialog
        open={isOpenPrivacyDialog}
        onClose={handleClosePrivacyDialog}
      />
      <StyledStack className={visibility ? 'show' : 'hide'}>
        <StyledPaperSup size="medium" aria-label="add" onClick={() => handleHide(false)} >
          <Grid item xs={12} sx={{}} >
            <Stack textAlign="center" spacing={-2}>
              <Typography variant="h3" component="p" color="#041E42" sx={{fontSize:'25px!important', lineHeight:'18px', padding:'20px', paddingTop:'10px'}} >¿Quieres Contratar?</Typography>
            </Stack>
          </Grid>
        </StyledPaperSup>
        <StyledPaper size="medium" aria-label="add" >
          <IconButton 
              sx={{
                position: 'absolute',
                top:0,
                right:0,
                color: "#fff",
                display: visibility ? 'inline-block' : 'none'
              }}
              onClick={() => handleHide(true)}
            >
              <CloseIcon sx={{ fontSize: "1.2rem", fontWeight:900 }}/>
            </IconButton>

            <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ marginLeft: '5%', marginRight: '5%', marginTop:'0%' }}>
              <Grid item xs={2.5} xl={2.8}>
                <Stack textAlign="left" spacing={-1}>
                  <Typography variant="body3" color="white" sx={{fontSize:{xl:'26px'}}}>TE LLAMAMOS</Typography>
                  <Typography  component="p" color="white" sx={{ fontSize:{ scrollMarginBlock:'2rem', md:'45px', lg:'55px', xl: '85px'}, fontFamily:'Galano Grotesque Bold' , lineHeight:{md:'45px',lg:'55px' ,xl:'60px'} }}>GRATIS</Typography>
                </Stack>
              </Grid>
              <Grid item xs={3.5} xl={3} sx={{justifyContent:'flex-start', display:'flex', flexDirection:'column'}}>
                <Typography variant="subtitle3" component="p" color="white" sx={{ fontSize:{  sm:'10px', md:'12px'}, fontWeight:'400', textAlign:'left' }} marginBottom= '0.3rem' marginTop="0.5rem">De lunes a viernes de 9 a 21h</Typography>
                <TextFieldWrapper value={val} onChange={handleTextFieldChange} inputProps={{ style: { fontSize: 18, color: '#041E42', backgroundColor:'white'}, maxLength:9 }} sx={{ width: '70%', borderRadius:'5px'}} onChange={e =>{handleTextFieldChange(e); onInputChange(e)}} label={<Typography style={{marginTop:'-4px'}}  variant="body2" color="#041E42">Tu teléfono</Typography>} variant="outlined" />
              </Grid>
              <Grid item xs={3} sx={{ textAlign: 'left' }}>
                <FormControlLabel
                  sx={{ marginTop: '4px', alignItems:'self-start'  }}
                  control={<Checkbox size="small" 
                  onChange={handleCheckboxChange}
                  sx={{
                    padding:'2px 9px',
                    color: 'white',
                    '&.Mui-checked': {
                      color: 'white',
                    },
                  }} />}
                  label={<Typography sx={{ lineHeight: '16px', fontWeight:'300', fontSize:{ sm:'9px', md:'10px', lg:'12px'}, color:'white' }} variant="subtitle3">He leído y acepto la <MuiLink 
                  onClick={() => {
                    handleClicPrivacy();
                  }} 
                  style={{color:'white', textDecorationColor:'white'}}>política de privacidad</MuiLink> y consiento el tratamiento de mis datos personales para todas las finalidades contenidas en la misma</Typography>}
                />

              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={() => {
                    setButtonClicked(true);
                    if (checked && isPhoneValid(phoneNumber)) {
                      launchC2C(phoneNumber);
                      handleClickOpen();
                    }
                  }}
                  sx={{
                    background: '#EC7A08',
                    color: '#FFFFFF',
                    borderRadius: '3px',
                    marginTop: '13px',
                    marginBottom: '10px',
                    height: '50px',
                    width: '80%',
                    '&:hover': {
                      backgroundColor: '#b15b06',
                      color: 'white !important',
                    }
                  }}>
                  <Typography variant="button" component="p" sx={{ letterSpacing: 1.9 , fontWeight:600, textTransform:'uppercase', fontSize:'18px', fontFamily:'Galano Grotesque Bold' }}>Llámame gratis</Typography>
                </Button>
                {!checked && buttonClicked && <Typography variant="subtitle1" color="white" sx={{fontSize:'14px!important'}}>Debes aceptar la política de privacidad</Typography>}
              {checked && buttonClicked && !isPhoneValid(phoneNumber) && <Typography variant="subtitle1" color="white" sx={{fontSize:'14px!important'}}>El número de teléfono no es válido</Typography>}
              </Grid>
            </Grid>
        </StyledPaper>
      </StyledStack>
    </>
  );
}

export default StickyC2CBottom;